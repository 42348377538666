<template>
  <ReportTable
    name="Mapview"
    item-key="trueIndex"
    :headers="headers"
    :provider="fetchMapProjects"
    data-field="data"
    :process-items="processItems"
    :filter-fields="filterFields"
    :copy-to-clipboard="false"
    :hide-show="false"
  >
    <template #actions-left>
      <div class="body-1 text--secondary my-3">Map view</div>
    </template>
    <template #actions-right>
      <AddMapViewDialog
        :refreshMapProjectsList="refreshTable"
        :mapProjects="mapProjects"
      />
      <EditMapViewDialog
        v-if="itemToEdit"
        v-model="itemToEdit"
        @edit-floor="editFloor"
      />
      <DeleteMapViewDialog
        v-if="itemToDelete"
        v-model="itemToDelete"
        @delete-floor="deleteFloor"
        @delete-all-floors="deleteAllFloors"
      />
    </template>
    <template #item.edit="{ item }">
      <v-row justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="itemToEdit = item"
        >
          <v-icon small> fa-pencil-alt </v-icon>
        </v-btn>
      </v-row>
    </template>
    <template #item.name="{ item }">
      <v-tooltip v-if="item.mapTypeCount + item.satelliteTypeCount >= 5" bottom>
        <template #activator="{ on }">
          <div class="truncate-text" v-on="on">{{ item.allNames }}</div>
        </template>
        <span>{{ item.allNames }}</span>
      </v-tooltip>
      <div v-else class="truncate-text">{{ item.allNames }}</div>
    </template>
    <template #item.mapStyle="{ item }">
      <v-chip
        v-if="item.mapTypeCount > 0"
        small
        rounded
        outlined
        color="#3E51B7"
      >
        <v-icon left>mdi-map</v-icon>
        {{ `Map (${item.mapTypeCount})` }}
      </v-chip>
      <v-chip
        v-if="item.satelliteTypeCount > 0"
        small
        rounded
        outlined
        color="success"
      >
        <v-icon left>mdi-satellite-variant</v-icon>
        {{ `Satellite (${item.satelliteTypeCount})` }}
      </v-chip>
    </template>
    <template #item.projectName="{ item }">
      {{ item.projectName }}
      <OpenInNewWindowButton
        :target-link="`${$config.public.dashURL}/projects/${item.projectId}/map`"
      />
    </template>
    <template #item.delete="{ item }">
      <v-row justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="itemToDelete = item"
        >
          <v-icon small color="red"> fas fa-trash </v-icon>
        </v-btn>
      </v-row>
    </template>
  </ReportTable>
</template>

<script>
import headers from "@/components/mapView/mapViewHeaders"
import filterFields from "@/components/mapView/mapViewSearchFilters"
import AddMapViewDialog from "@/components/mapView/AddMapViewDialog"
import EditMapViewDialog from "@/components/mapView/EditMapViewDialog"
import DeleteMapViewDialog from "@/components/mapView/DeleteMapViewDialog"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ReportTable from "@/components/ReportTable"
import { MapType } from "@evercam/shared/types/map"

export default {
  name: "ViewsMap",
  components: {
    OpenInNewWindowButton,
    AddMapViewDialog,
    EditMapViewDialog,
    DeleteMapViewDialog,
    ReportTable,
  },
  data() {
    return {
      itemToEdit: null,
      itemToDelete: null,
      filterFields,
      headers,
      mapProjects: null,
    }
  },
  methods: {
    async fetchMapProjects() {
      const response = await fetch(
        `${this.$config.public.firebaseDbLink}data/projects/maps.json`
      )
      this.mapProjects = await response.json()

      return { data: this.mapProjects }
    },
    processItems(items) {
      const processedItems = Object.keys(items).map((key, index) => {
        const item = items[key][0]
        const allNames = items[key].map((floor) => floor.name).join(", ")
        const satelliteTypeCount = items[key].filter(
          (floor) => floor.mapStyle == MapType.Hybrid
        ).length
        const mapTypeCount = items[key].filter(
          (floor) => floor.mapStyle == MapType.Terrain
        ).length
        const allFloors = items[key]

        return {
          ...item,
          trueIndex: index,
          allNames,
          satelliteTypeCount,
          mapTypeCount,
          allFloors,
        }
      })

      return processedItems
    },

    refreshTable() {
      this.$root.$emit("refresh-report-table")
    },

    async deleteFloor(itemToDelete) {
      let data = this.mapProjects[itemToDelete.projectId]
      data = data.filter((el) => el.name !== itemToDelete.name)
      try {
        await fetch(
          `${this.$config.public.firebaseDbLink}data/projects/maps/${itemToDelete.projectId}.json`,
          { method: "PUT", body: JSON.stringify({ ...data }) }
        )
        this.refreshTable()
        this.$notifications.success("Floor successfully deleted")
      } catch (error) {
        this.$notifications.error({
          text: "Failed to delete the floor!",
          error,
        })
      } finally {
        this.itemToDelete = null
      }
    },
    async deleteAllFloors(projectId) {
      try {
        await fetch(
          `${this.$config.public.firebaseDbLink}data/projects/maps/${projectId}.json`,
          { method: "DELETE" }
        )
        this.refreshTable()
        this.$notifications.success("All floors successfully deleted")
      } catch (error) {
        this.$notifications.error({
          text: "Failed to delete all floors!",
          error,
        })
      } finally {
        this.itemToDelete = null
        this.deletingDialog = false
      }
    },

    async editFloor(floor) {
      try {
        const data = this.mapProjects[floor.projectId]
        if (!data) {
          this.$notifications.error("Project not found!")

          return
        }
        let indexToReplace = data.findIndex((el) => el.name === floor.name)
        data[indexToReplace] = floor
        await fetch(
          `${this.$config.public.firebaseDbLink}data/projects/maps/${floor.projectId}.json`,
          { method: "PATCH", body: JSON.stringify({ ...data }) }
        )

        this.refreshTable()
        this.$notifications.success("Floor successfully edited")
      } catch (error) {
        this.$notifications.error("Failed to edit the floor!", error)
      } finally {
        this.itemToEdit = null
      }
    },
  },
}
</script>

<style scoped>
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
</style>
