<template>
  <v-dialog v-model="deletingDialog" width="500">
    <v-card>
      <v-card-title>
        <span>Delete Map View</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <v-row>
              <v-col cols="12" class="py-0">
                <p>
                  Are you sure you want to delete this map view ?. If you are
                  not from the 3D team please contact them before doing this
                  action.
                </p>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.projectName"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project Name"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Floor/Site Name"
                  rules="required"
                >
                  <v-select
                    v-model="floor.name"
                    dense
                    outlined
                    :error-messages="errors"
                    :items="
                      allFloorsNames.length > 1
                        ? [...allFloorsNames, 'All']
                        : allFloorsNames
                    "
                    label="Floor/Site Name"
                    @change="selectFloorToDelete"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deletingDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text :disabled="invalid" @click="deleteFloor">
            Delete
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      floor: {
        projectId: null,
        projectName: null,
        name: null,
      },
    }
  },
  computed: {
    deletingDialog: {
      get() {
        return !!this.value
      },
      set(value) {
        if (!value) {
          this.$emit("input", null)
        }
      },
    },
    allFloorsNames() {
      return this.value.allFloors.map((floor) => floor.name)
    },
  },
  mounted() {
    this.floor = { ...this.value.allFloors[0] }
  },
  methods: {
    async deleteFloor() {
      this.floor.name === "All"
        ? this.$emit("delete-all-floors", this.floor.projectId)
        : this.$emit("delete-floor", this.floor)
    },
    selectFloorToDelete(value) {
      if (value === "All") {
        this.floor = {
          projectName: this.value.projectName,
          projectId: this.value.projectId,
          name: "All",
        }
      } else {
        this.floor = {
          ...this.value.allFloors.find((map) => map.name === value),
        }
      }
    },
  },
}
</script>
