<template>
  <v-dialog v-model="editingDialog" width="500">
    <v-card>
      <v-card-title>
        <span>Edit Map View</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <v-row>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.projectName"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project Name"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.projectId"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Floor/Site Name"
                  rules="required"
                >
                  <v-select
                    v-model="floor.name"
                    dense
                    outlined
                    :error-messages="errors"
                    :items="allFloorsNames"
                    label="Floor/Site Name"
                    @change="selectFloorToEdit"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Map Style"
                  rules="required"
                >
                  <v-select
                    v-model="floor.mapStyle"
                    :items="mapStyle"
                    label="Map Style"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </v-col>
              <v-row class="px-3">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="East"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.east"
                      dense
                      outlined
                      :error-messages="errors"
                      label="East"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="West"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.west"
                      dense
                      outlined
                      :error-messages="errors"
                      label="West"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="North"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.north"
                      dense
                      outlined
                      :error-messages="errors"
                      label="North"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="South"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.south"
                      dense
                      outlined
                      :error-messages="errors"
                      label="South"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Image URL"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.image"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Image URL"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editingDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="editFloor">
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { MapTypes } from "@/components/constants"
export default {
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      floor: {
        projectId: null,
        projectName: null,
        name: null,
        east: null,
        west: null,
        north: null,
        south: null,
        image: null,
        mapStyle: null,
      },
      mapStyle: MapTypes,
    }
  },
  computed: {
    editingDialog: {
      get() {
        return !!this.value
      },
      set(value) {
        if (!value) {
          this.$emit("input", null)
        }
      },
    },
    allFloorsNames() {
      return this.value.allFloors.map((floor) => floor.name)
    },
  },
  mounted() {
    this.floor = { ...this.value.allFloors[0] }
  },
  methods: {
    async editFloor() {
      this.$emit("edit-floor", this.floor)
    },
    selectFloorToEdit(value) {
      this.floor = {
        ...this.value.allFloors.find((map) => map.name === value),
      }
    },
  },
}
</script>
