import { MapTypes } from "@/components/constants.js"

export default {
  projectName: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  name: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  projectId: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  mapStyle: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: null }, ...MapTypes],
      value: null,
    },
    offline: true,
  },
}
