export default [
  {
    value: "edit",
    text: "",
    filterable: false,
  },
  {
    value: "projectName",
    text: "Project Name",
    sortable: true,
  },
  {
    value: "name",
    text: "Map View Name (s)",
    sortable: true,
  },
  {
    value: "projectId",
    text: "Project ID",
    width: 150,
    sortable: true,
  },
  {
    value: "mapStyle",
    text: "Map Style",
    width: 150,
    sortable: true,
  },
  {
    value: "delete",
    text: "",
    sortable: true,
  },
]
